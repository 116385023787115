<script lang="ts" setup>
  defineProps({
    product: { type: Object, required: true },
  });

  const variant = defineModel('variant', { type: Object, required: false, default: undefined });
  const open = defineModel('open', { type: Boolean, required: false, default: false });
  const success = defineModel('success', { type: Boolean, required: false, default: false });
  const related = defineModel('related', { type: Array, required: false, default: [] });

  function onVariantAdded(res) {
    related.value = res.related;
    success.value = true;
    open.value = false;
  }
</script>
<template>
  <SfModal v-model:open="open" class="cart-button-picker-modal">
    <SfModalHeader v-model:open="open">
      {{ $t('Wybierz wariant') }}
    </SfModalHeader>
    <section class="cart-button-picker-modal__section">
      <div class="cart-button-picker-modal__picker">
        <div class="cart-button-picker-modal__photo">
          <MediaPicture :photo="variant?.photo || product.photo" sizes="490x490 2x, 245x245" />
        </div>
        <div class="cart-button-picker-modal__content">
          <h3>{{ variant?.title || product.title }}</h3>
          <SfStars :rating="product.reviews_average" :count="product.reviews_count" />
          <SfVariantPicker v-model:variant="variant" :product="product" />
          <SfProductCatalogPrice v-if="variant" :item="variant" />
          <SfProductCatalogPrice v-else :item="product" />
          <div class="cart-button-picker-modal__pricing">
            <SfProductPrice v-if="variant" :price="variant" />
            <SfProductPrice v-else :price="product" />
            <SfProductStock v-if="variant" :variant="variant" />
          </div>
          <SfProductOmnibus v-if="variant" :price="variant" />
          <SfProductOmnibus v-else :price="product" />
          <SfCartStoreButton :variant="variant" @added="onVariantAdded" />
        </div>
      </div>
    </section>
  </SfModal>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .cart-button-picker-modal {

    &__section {
      flex-grow: 1;
    }

    &__picker {
      display: flex;
      gap: 1rem;
    }

    &__photo {
      display: flex;
      flex: 0 0 245px;
      flex-direction: column;
      gap: 1rem;
    }

    &__content {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      gap: 1rem;

      h3 {
        font-size: 1.25rem;
      }
    }

    &__pricing {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }


  @include media.query(md) {

    .cart-button-picker-modal {

      &__picker {
        flex-direction: column;

        .variations {
          margin: 1.25rem 0;
        }
      }

      &__pricing {
        margin-top: 0.625rem;

        .current .money {
          font-size: 1.5rem;
        }
      }
    }
  }
</style>
