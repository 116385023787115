<script setup lang="ts">
  import { route, $post } from '@plenny/visitor';
  import { ref, type Ref } from 'vue';

  const open = ref(false);
  const input = ref() as Ref<HTMLInputElement>;

  function onSubmit() {
    let data = new FormData();

    if (input.value.files) {
      data.set('file', input.value.files[0]);
    }

    return $post(route('api.v1.web.cart.item.import'), data).then(() => {
      open.value = false;
    });
  }
</script>
<template>
  <SfButton transparent small @click="open = true">
    <template #before>
      <SfIconBoxArrowInDown />
    </template>
    <template #default>
      {{ $t('Import z pliku') }}
    </template>
  </SfButton>
  <SfModal v-model:open="open">
    <SfModalHeader v-model:open="open">
      <h2>{{ $t('Importuj koszyk z pliku') }}</h2>
    </SfModalHeader>
    <VisitorForm :submit="onSubmit" v-slot="{ submitting }">
      <p>{{ $t('Możesz wgrać plik w rozszerzeniu CSV, XLS lub XLSX, aby łatwo zaimportować produkty do koszyka. Pierwsza kolumna powinna zawierać kod SKU, EAN lub kod producenta. Natomiast druga kolumna ilość produktu, którą chcesz dodać do koszyka.') }}</p>
      <div class="file-wrapper">
        <SfControlWrapper name="file" :label="$t('Wybierz plik do importu')" required>
          <input ref="input" class="control" type="file" name="file" required />
        </SfControlWrapper>
      </div>
      <SfModalFooter>
        <SfButton @click="open = false">
          {{ $t('Anuluj') }}
        </SfButton>
        <SfButton type="submit" primary :loading="submitting">
          {{ $t('Zapisz') }}
        </SfButton>
      </SfModalFooter>
    </VisitorForm>
  </SfModal>
</template>
<style scoped lang="scss">
  .file-wrapper {
    margin: 1rem 0;
  }
</style>
