<script setup lang="ts">
  import { ref, type PropType, computed } from 'vue';
  import type { Section } from '$types';

  const props = defineProps({
    product: { type: Object, required: true },
    sections: { type: Array as PropType<Section[]>, required: false, default: [] },
    recommended: { type: Array, required: false, default: [] },
    reviews: { type: Object, required: true },
    reviewAvailable: { type: Boolean, required: true },
    reviewsSummary: { type: Object, required: true },
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 1.25,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1400: {
        slidesPerView: 4,
      },
    },
  });

  const showSections = computed(() => {
    return props.sections.length > 0;
  });

  const showSpecification = computed(() => {
    return props.product.list_attributes.length > 0;
  });

  const showSafety = computed(() => {
    return (
      props.product.responsible_manufacturer ||
      props.product.responsible_entity ||
      props.product.product_importers.length > 0 ||
      props.product.safety_notes.length > 0
    );
  });

  const showReviews = computed(() => {
    return props.reviewAvailable || props.reviews.data.length > 0;
  });

  const showRecommended = computed(() => {
    return props.recommended.length > 0;
  });

  const show = computed(() => {
    return showSections.value || showSpecification.value || showSafety.value || showReviews.value || showRecommended.value;
  });
</script>
<template>
  <div v-if="show" class="container">
    <div class="wrapper description-wrapper">

      <ShopProductViewDescriptionSection v-if="showSections" :title="$t('Opis')">
        <SectionRenderer :sections />
      </ShopProductViewDescriptionSection>

      <ShopProductViewDescriptionSection v-if="showSpecification" :title="$t('Specyfikacja')">
        <ShopProductViewDescriptionSpecification :product />
      </ShopProductViewDescriptionSection>

      <ShopProductViewDescriptionSection v-if="showSafety" :title="$t('Bezpieczeństwo')">
        <ShopProductViewDescriptionSafety :product />
      </ShopProductViewDescriptionSection>

      <ShopProductViewDescriptionSection v-if="showReviews" :title="$t('Opinie')">
        <ShopProductViewDescriptionReviews :product :reviews :reviewAvailable :reviewsSummary />
      </ShopProductViewDescriptionSection>

      <ShopProductViewDescriptionSection v-if="showRecommended" :title="$t('Podobne produkty')">
        <div class="products-wrapper">
          <SfProductsCarousel :products="recommended" :parameters />
        </div>
      </ShopProductViewDescriptionSection>

    </div>
  </div>
</template>
<style scoped lang="scss">
  .description-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    padding: 60px;
    background-color: var(--color-white);

    .products-wrapper {
      .products-carousel {
        :deep(.swiper-container) {
          .wrap {
            .slide .product {
              flex-grow: 0;
              width: auto;
            }
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .description-wrapper {
      margin: 15px auto;
      padding: 26px 10px;

      .product-section {
        padding: 26px 20px 20px 20px;
      }
    }
  }

  @media (max-width: 768px) {
    .description-wrapper {
      gap: 2.5rem;

      .reviews-wrapper {
        padding-top: 0.75rem;

        .head {
          margin: 0 auto;
          width: 250px;
          justify-content: center;

          :deep(.stars-wrapper) {
            svg {
              font-size: 18px;
            }

            span {
              font-size: 1rem;
            }
          }
        }
      }

      .products-wrapper {
        margin-top: 12px;
      }

      .product-section {
        padding: 15px;
      }
    }
  }
</style>
