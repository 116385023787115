<script lang="ts" setup>
  import { type PropType, computed } from 'vue';
  import { useBreakpoints } from '$storefront';
  import { $get, route } from '@plenny/visitor';
  import type { Pagination } from '$types';

  interface RelatedDocument {
    type: number,
    documentId: number,
    documentDefinition: string,
    documentDate: string,
    documentNumber: string,
    numberExternal: string,
  }

  type WarehouseDocument = {
    documentType: string;
    documentSymbol: string;
    documentDate: string;
    download: string | null;
    relations: RelatedDocument[];
  }

  defineOptions({
    layout: ['StoreLayout', 'AccountLayout'],
    inheritAttrs: false,
  });

  const props = defineProps({
    documents: { type: Object as PropType<Pagination<WarehouseDocument>>, required: true },
    from: { type: String, required: false },
    to: { type: String, required: false },
  });

  const breakpoints = useBreakpoints();

  const initial = computed(() => {
    return {
      from: props.from,
      to: props.to,
    };
  });

  function handleFilters(data) {
    return $get(route('web.account.warehouse.documents.index', data));
  }
</script>
<template>
  <AccountMobileNavigation :href="route('web.account.show')">
    {{ $t('Moje zobowiązania') }}
  </AccountMobileNavigation>
  <SfTile large class="account-warehouse-documents">
    <template #title v-if="breakpoints.lg">
      <h1 class="account__heading">{{ $t('Dokumenty magazynowe') }}</h1>
    </template>
    <template #default>
      <div class="account-warehouse-documents-filters">
        <VisitorForm ref="form" :initial="initial" @submit="handleFilters">
          <div class="col">
            <SfFormInput type="date" :label="$t('Od:')" name="from" @change="$refs.form.submit()" />
          </div>
          <div class="col">
            <SfFormInput type="date" :label="$t('Do:')" name="to" @change="$refs.form.submit()" />
          </div>
        </VisitorForm>
      </div>
      <div v-if="documents.data.length > 0" class="account-warehouse-documents__content">
        <SfListCard v-for="document in documents.data" :key="document.documentSymbol" class="account-warehouse-documents">
          <template #default>
            <div class="account-warehouse-documents__header">
              <h2 class="account-warehouse-documents__heading">{{ document.documentType }} {{ document.documentSymbol }}</h2>
              <p class="account-warehouse-documents__date">
                {{ $t('z dnia') }}
                <SfDateTime :value="document.documentDate" />
              </p>
            </div>
            <div class="account-warehouse-documents__details">
              <span v-for="related in document.relations" class="account-warehouse-documents__related">
                <span>
                  <small>{{ related.type === 1 ? $t('Korekta do:') : $t('Dotyczy:') }}</small>
                </span>&nbsp;
                <span>
                  {{ related.documentDefinition }} {{ related.numberExternal }}
                </span>&nbsp;
                <span>
                  <small>{{ $t('z dnia') }}</small>
                </span>&nbsp;
                <SfDateTime :value="related.documentDate" />
              </span>
            </div>
          </template>
          <template #actions>
            <SfButton :href="document.download || ''" :disabled="!document.download" small target="_blank">
              {{ document.download ? $t('Pobierz dokument') : $t('Dokument niedostępny') }}
            </SfButton>
          </template>
        </SfListCard>
        <SfPagination :pagination="documents" />
      </div>
      <SfDataPlaceholder v-else>
        {{ $t('Brak danych do wyświetlenia.') }}
      </SfDataPlaceholder>
    </template>
  </SfTile>
</template>

<style lang="scss">
  @use "$assets/mixins/media";

  .account-warehouse-documents-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .account-warehouse-documents {
    .list-card__content-default {
      gap: 0;
    }

    &__content {
      display: flex;
      flex-direction: column;
      row-gap: 1.25rem;
    }

    &__details {
      display: flex;
      flex-direction: column;

      small {
        font-weight: var(--font-weight-regular);
      }
    }

    &__header {
      display: flex;
      align-items: center;
      column-gap: 0.625rem;
    }

    &__heading {
      font-size: 1.25rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__date,
    &__count {
      font-size: 0.875rem;
      color: var(--sf-account-color-dark-grey);
    }

    &__overdue {
      font-weight: var(--font-weight-bold);
      color: var(--danger-color);
    }

    &__price {
      font-weight: var(--font-weight-bold);
      margin-top: 0.125rem;
    }

    &__related {
      font-weight: var(--font-weight-bold);
      font-size: 0.875rem;
      margin-top: 0.125rem;
    }
  }

  @include media.query(lg) {
    .account-warehouse-documents {
      row-gap: 1rem;

      &__content .list-card__actions {
        gap: 0.75rem;
      }
    }
  }
</style>
