<script setup lang="ts">
  import { useSession, useVisitorForm, $get, route } from '@plenny/visitor';
  import { ref, onMounted } from 'vue';

  const loading = ref(true);
  const open = ref(false);
  const addresses = ref([]);

  const session = useSession();
  const { data, set } = useVisitorForm();

  function onSelected(address: any) {
    set('shipping_company', address.company);
    set('shipping_name', address.name);
    set('shipping_street', address.street);
    set('shipping_building_nr', address.building_nr);
    set('shipping_apartment_nr', address.apartment_nr);
    set('shipping_first_line', address.first_line);
    set('shipping_second_line', address.second_line);
    set('shipping_third_line', address.third_line);
    set('shipping_postcode', address.postcode);
    set('shipping_city', address.city);
    set('shipping_region', address.region);
    set('shipping_email', address.email);
    set('shipping_phone', address.phone);

    open.value = false;
  }

  function fillFromBilling() {
    set('shipping_company', data.value.billing_company);
    set('shipping_name', data.value.billing_name);
    set('shipping_street', data.value.billing_street);
    set('shipping_building_nr', data.value.billing_building_nr);
    set('shipping_apartment_nr', data.value.billing_apartment_nr);
    set('shipping_first_line', data.value.billing_first_line);
    set('shipping_second_line', data.value.billing_second_line);
    set('shipping_third_line', data.value.billing_third_line);
    set('shipping_postcode', data.value.billing_postcode);
    set('shipping_city', data.value.billing_city);
    set('shipping_region', data.value.billing_region);
    set('shipping_email', data.value.billing_email);
    set('shipping_phone', data.value.billing_phone);
  }

  function isShippingAddressEmpty() {
    return (
      !data.value.billing_company &&
      !data.value.billing_name &&
      !data.value.billing_street &&
      !data.value.billing_building_nr &&
      !data.value.billing_apartment_nr &&
      !data.value.billing_first_line &&
      !data.value.billing_second_line &&
      !data.value.billing_third_line &&
      !data.value.billing_postcode &&
      !data.value.billing_city &&
      !data.value.billing_region &&
      !data.value.billing_email &&
      !data.value.billing_phone
    );
  }

  onMounted(() => {
    $get(route('api.v1.web.cart.address.shipping.show')).then((respose) => {
      addresses.value = respose;
      loading.value = false;

      let defaultAddress = addresses.value.find((address: any) => {
        return address.default === true;
      });

      if (defaultAddress && isShippingAddressEmpty()) {
        onSelected(defaultAddress);
      }
    });
  });
</script>
<template>
  <div class="shipping-address">
    <div class="shipping-address-container">
      <div class="shipping-address-actions">
        <div v-if="session.is_authenticated && addresses.length" class="from-saved-addresses">
          <SfButton primary type="button" @click="open = true">
            <template #before>
              <SfIconBook />
            </template>
            <template #default>
              {{ $t('Wypełnij z zapisanych adresów') }}
            </template>
          </SfButton>
          <SfModal v-model:open="open">
            <SfModalHeader v-model:open="open">
              {{ $t('Zapisane Adresy') }}
            </SfModalHeader>
            <ShippingCourierPicker :addresses="addresses" :loading="loading" @selected="onSelected" />
          </SfModal>
        </div>
        <div class="from-billing-address">
          <SfButton primary type="button" @click="fillFromBilling">
            <template #before>
              <SfIconBoxArrowDown />
            </template>
            <template #default>
              {{ $t('Wypełnij z danych kupującego') }}
            </template>
          </SfButton>
        </div>
      </div>
      <div class="shipping-address-form">
        <SfAddressForm
          prefix="shipping"
          external-country="country_code"
          named contactable
          name-required
        />
      </div>
      <div v-if="session.is_authenticated" class="shipping-address-save">
        <SfFormSwitch name="shipping_save_address" :label="$t('Zapisz jako nowy adres dostawy')" />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .shipping-address {
    .shipping-address-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    .shipping-address-actions {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    .shipping-address-form {
      max-width: 672px;
    }
  }
</style>
