<script lang="ts" setup>
  import { type PropType, ref, computed } from 'vue';
  import { useVariantStock } from '$storefront';
  import { trans } from '@plenny/translator';
  import { UnitFormatter } from '$support';
  import { Unit } from '$types';
  import { useShared } from '@plenny/visitor';

  interface StockMeta {
    total: number;
    current: number;
    levels: Record<number, string>;
  }

  interface Inventory {
    id: number;
    stock_on_order: boolean;
    stock_available: number;
    stock_unit: Unit,
    stock_meta: StockMeta | undefined;
  }

  interface Warehouse {
    id: number;
    first_line: string | null;
    second_line: string | null;
    third_line: string | null;
    city: string;
  }

  const props = defineProps({
    variant: { type: Object as PropType<Inventory>, required: false },
  });

  const showMore = ref(false);

  const variant = computed(() => props.variant);

  const { stockOnOrder, inStock, amountInStock, amountInCart } = useVariantStock(variant);
  const shared = useShared();

  const selectedWarehouse = computed(() => {
    return shared.value.currentPickupPoint as Warehouse;
  });

  const warehouses = computed(() => {
    return (shared.value.availablePickupPoints ?? []) as Warehouse[];
  });

  const stockLevels = computed(() => {
    if (props.variant && props.variant.stock_meta) {
      return Object.keys(props.variant.stock_meta.levels).map((key) => {
        let warehouse = warehouses.value.find((warehouse) => String(warehouse.id) === key)!;
        let amount = props.variant?.stock_meta?.levels[key] || '0.0000';
        let unit = props.variant?.stock_unit || Unit.PIECE;
        let availablity = trans('Niedostępny');
        let available = Number(amount) > 0;
        let unavailable = !available;

        if (available) {
          availablity = trans('Dostępne <b>:amount</b>', { amount: UnitFormatter.accusative(unit, amount) });
        }

        return { warehouse, amount, availablity, classes: { available, unavailable } };
      });
    }

    return [];
  });

  const availabilityMessage = computed(() => {
    let amount = amountInStock.value;
    let unit = props.variant?.stock_unit;

    if (props.variant && unit) {
      if (!stockOnOrder.value) {
        if (inStock.value) {
          if (stockLevels.value.length > 1) {
            return trans('We wszystkich magazynach dostępne <b>:amount</b>.', { amount: UnitFormatter.accusative(unit, amount) });
          } else {
            return trans('Dostępność w magazynie <b>:amount</b>.', { amount: UnitFormatter.accusative(unit, amount) });
          }
        } else {
          return trans('<b>Produkt niedostępny.</b>');
        }
      } else {
        return trans('<b>Produkt dostępny na zamówienie.</b>');
      }
    } else {
      return trans('<b>Wybierz opcje aby sprawdzić dostępność.</b>');
    }
  });

  const currentMessage = computed(() => {
    if (stockOnOrder.value || !selectedWarehouse.value || stockLevels.value.length <= 1) {
      return undefined;
    }

    let amount = props.variant?.stock_meta?.current || '0.0000';
    let unit = props.variant?.stock_unit || Unit.PIECE;

    if (props.variant && unit) {
      if (Number(amount) > 0) {
        return trans('Dostępne <b>:amount</b> w magazynie <b>:warehouse</b>.', { amount: UnitFormatter.accusative(unit, amount), warehouse: selectedWarehouse.value.city });
      } else {
        return trans('<b>Produkt niedostępny w magazynie :warehouse.</b>', { warehouse: selectedWarehouse.value.city });
      }
    }

    return undefined;
  });

  const inCartMessage = computed(() => {
    let unit = props.variant?.stock_unit;
    let amount = amountInCart.value;

    if (amount && unit) {
      return trans('Masz <b>:amount</b> w koszyku.', { amount: UnitFormatter.accusative(unit, amount) });
    }
  });

  function openMore() {
    if (inStock.value && stockLevels.value.length > 1) {
      showMore.value = true;
    }
  }
</script>
<template>
  <div class="stock-container">
    <div class="stock" aria-live="polite">
      <SfIconBoxSeam class="stock__icon" />
      <div class="stock__availability">
        <span v-if="currentMessage" v-html="currentMessage" />
        <span v-html="availabilityMessage" />
        <span v-if="inCartMessage" v-html="inCartMessage" />
        <u v-if="inStock && stockLevels.length > 1" @click="openMore">
          {{ $t('Sprawdź dostępność w magazynach') }}
        </u>
      </div>
    </div>
    <SfModal v-model:open="showMore">
      <SfModalHeader v-model:open="showMore">
        <h2>Dostępność</h2>
      </SfModalHeader>
      <div class="stock__levels">
        <SfListCard v-for="level in stockLevels" small class="stock__level">
          <template #default>
            <address class="stock__level__address">
              <p v-if="level.warehouse.city" class="stock__level__address__name" v-text="level.warehouse.city" />
              <p v-if="level.warehouse.first_line" class="stock__level__address__line" v-text="level.warehouse.first_line" />
              <p v-if="level.warehouse.second_line" class="stock__level__address__line" v-text="level.warehouse.second_line" />
              <p v-if="level.warehouse.third_line" class="stock__level__address__line" v-text="level.warehouse.third_line" />
            </address>
          </template>
          <template #actions>
            <p class="stock__level__availability" :class="level.classes" v-html="level.availablity" />
          </template>
        </SfListCard>
      </div>
    </SfModal>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-stock-gap: 0.625rem;
    --sf-stock-icon-size: 1rem;
    --sf-stock-font-size: 0.75rem;

    @include media.query(md) {
      --sf-stock-font-size: 0.625rem;
    }
  }

  .stock {
    display: flex;
    align-items: flex-start;
    gap: var(--sf-stock-gap);

    &__icon {
      flex: 0 0 var(--sf-stock-icon-size);
      font-size: var(--sf-stock-icon-size);
    }

    &__availability {
      display: flex;
      flex-direction: column;
      font-size: var(--sf-stock-font-size);
      line-height: var(--line-height);

      u {
        display: block;
        cursor: pointer;
        margin-top: 0.5rem;

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }

  .stock__levels {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .stock__level__address {
    font-size: 0.75rem;
    font-style: normal;

    &__name {
      font-weight: var(--font-weight-bold);
    }
  }

  .stock__level__availability {
    font-weight: var(--font-weight-bold);
    font-size: 0.75rem;

    &.available {
      color: var(--sf-status-color-success);
    }

    &.unavailable {
      color: var(--sf-status-color-danger);
    }
  }
</style>
