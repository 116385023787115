<script lang="ts" setup>
  import { route, useShared, $patch } from '@plenny/visitor';
  import { computed } from 'vue';

  interface Warehouse {
    id: number;
    first_line: string | null;
    second_line: string | null;
    third_line: string | null;
    city: string | null;
  }

  const open = defineModel('open', { type: Boolean, required: false, default: false });
  const shared = useShared();

  const selected = computed(() => {
    return shared.value.currentPickupPoint as Warehouse;
  });

  const warehouses = computed(() => {
    return (shared.value.availablePickupPoints ?? []) as Warehouse[];
  });

  function selectWarehouse(warehouse: Warehouse) {
    $patch(route('api.v1.web.warehouse.update'), { warehouse: warehouse.id }).then(() => {
      open.value = false;
    });
  }
</script>
<template>
  <SfDrawer v-model:open="open" class="warehouses-drawer">
    <SfDrawerHeader v-model:open="open">
      {{ $t('Wybierz magazyn') }}
    </SfDrawerHeader>

    <div class="warehouses">
      <template v-if="warehouses.length > 0">
        <SfListCard v-for="warehouse in warehouses" :key="warehouse.id" class="warehouse" small interactive :selected="warehouse.id === selected.id" @click="selectWarehouse(warehouse)">
          <template #default>
            <address class="warehouse-address">
              <p v-if="warehouse.city" class="warehouse-address-name" v-text="warehouse.city" />
              <p v-if="warehouse.first_line" class="warehouse-address-line" v-text="warehouse.first_line" />
              <p v-if="warehouse.second_line" class="warehouse-address-line" v-text="warehouse.second_line" />
              <p v-if="warehouse.third_line" class="warehouse-address-line" v-text="warehouse.third_line" />
            </address>
          </template>
        </SfListCard>
      </template>
      <template v-else>
        <SfDataPlaceholder>
          <p>{{ $t('Brak magazynów do wyboru.') }}</p>
        </SfDataPlaceholder>
      </template>
    </div>
  </SfDrawer>
</template>
<style scoped lang="scss">
  .warehouses {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .warehouse-address {
    font-style: normal;
  }

  .warehouse-address-name {
    font-weight: var(--font-weight-bold);
  }

  .warehouse-address-line {
    font-size: 0.75rem;
  }
</style>
