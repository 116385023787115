<script setup lang="ts">
  import { type PropType, computed } from 'vue';
  import { trans } from '@plenny/translator';
  import { UnitFormatter } from '$support';
  import { Unit } from '$types';

  interface Product {
    stock_unit: Unit;
    stock_available: string;
    stock_on_order: true;
  }

  const props = defineProps({
    product: { type: Object as PropType<Product>, required: true },
  });

  const onOrder = computed(() => {
    return props.product.stock_on_order;
  });

  const available = computed(() => {
    return onOrder.value || Number(props.product.stock_available) > 0;
  });

  const message = computed(() => {
    if (onOrder.value) {
      return trans('Dostępny na zamówienie');
    } else if (available.value) {
      return trans('Dostępne :amount', { amount: UnitFormatter.accusative(props.product.stock_unit, props.product.stock_available) });
    } else {
      return trans('Brak w magazynie');
    }
  });
</script>
<template>
  <div class="product-availability" :class="{ available }" v-html="message" />
</template>
<style scoped lang="scss">
  .product-availability {
    display: flex;
    align-items: center;
    font-size: 0.625rem;
    text-transform: uppercase;
    font-weight: 600;
    gap: 0.75rem;

    &:before {
      content: "";
      background-color: #f87171;
      border: 1px solid #ef444488;
      box-shadow: 0 0 3px 1px #f87171fa;
      border-radius: 5px;
      height: 8px;
      width: 8px;
    }

    &.available {
      &:before {
        background-color: #a3e635;
        border-color: #84cc1688;
        box-shadow: 0 0 3px 1px #a3e635fa;
      }
    }
  }
</style>
