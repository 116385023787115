<script setup lang="ts">
  import { computed, type PropType } from 'vue';
  import { trans } from '@plenny/translator';
  import { useVisitorForm } from '@plenny/visitor';
  import { useCountryAddresses } from '$storefront';
  import type { Country } from '$types';

  const defaultLabels = {
    custom: trans('Chcę podać inne dane'),
    country_code: trans('Kraj'),
    type: trans('Rodzaj'),
    type_personal: trans('Osobisty'),
    type_company: trans('Firmowy'),
    name: trans('Imię i nazwisko'),
    company: trans('Nazwa firmy'),
    street: trans('Ulica'),
    building_nr: trans('Nr bud.'),
    apartment_nr: trans('Nr lok.'),
    postcode: trans('Kod pocztowy'),
    city: trans('Miejscowość'),
    region: trans('Województwo'),
    first_line: trans('Adres'),
    second_line: trans('Adres c.d.'),
    vat_id: trans('NIP'),
    email: trans('E-mail'),
    phone: trans('Telefon'),
  };

  const props = defineProps({
    billable: {
      type: Boolean,
      required: false,
      default: false,
    },
    contactable: {
      type: Boolean,
      required: false,
      default: false,
    },
    contactRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    externalCountry: {
      type: [String, Boolean] as PropType<string | false>,
      required: false,
      default: false,
    },
    externalType: {
      type: [String, Boolean] as PropType<string | false>,
      required: false,
      default: false,
    },
    named: {
      type: Boolean,
      required: false,
      default: false,
    },
    nameRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    pointable: {
      type: Boolean,
      required: false,
      default: false,
    },
    customizable: {
      type: Boolean,
      required: false,
      default: false,
    },
    prefix: {
      type: [String, Boolean] as PropType<string | false>,
      required: false,
      default: false,
    },
    typed: {
      type: Boolean,
      required: false,
      default: false,
    },
    countries: {
      type: Array as PropType<Country[]>,
      required: false,
      default: [],
    },
    labels: {
      type: Object as PropType<Partial<typeof defaultLabels>>,
      required: false,
      default: {},
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  });

  const form = useVisitorForm();
  const addresses = useCountryAddresses();

  function prefix(name: string) {
    if (props.prefix) {
      return props.prefix + '_' + name;
    } else {
      return name;
    }
  }

  const country = computed(() => {
    return form.get(props.externalCountry || prefix('country_code'));
  });

  const type = computed(() => {
    return form.get(props.externalType || prefix('type'));
  });

  const isImplicitAddress = computed(() => {
    return addresses.isAddressImplicit(country.value);
  });

  const labels = computed(() => {
    return { ...defaultLabels, ...props.labels };
  });

  const types = computed(() => {
    return [
      { value: 'personal', label: labels.value.type_personal },
      { value: 'company', label: labels.value.type_company },
    ];
  });

  const custom = computed(() => {
    if (props.customizable) {
      return form.get(prefix('custom'));
    } else {
      return true;
    }
  });
</script>
<template>
  <section class="address-form">
    <div v-if="externalCountry === false" class="address-form__line">
      <SfFormSelect :label="labels.country_code" :options="countries" :name="prefix('country_code')" required :readonly="readonly" />
    </div>
    <div v-if="typed && externalType === false" class="address-form__line address-form__type">
      <SfFormRadio :label="labels.type" :name="prefix('type')" :options="types" :readonly="readonly" />
    </div>
    <slot v-if="customizable" name="custom" />
    <div v-if="customizable" class="address-form__line address-form__custom">
      <SfFormSwitch :label="labels.custom" :name="prefix('custom')" :readonly="readonly" />
    </div>
    <template v-if="custom">
      <template v-if="named">
        <div v-if="!typed || type === 'personal'" class="address-form__line">
          <SfFormInput type="text" :label="labels.name" :name="prefix('name')" :required="(typed && type === 'personal') || nameRequired" :readonly="readonly" />
        </div>
        <div v-if="!typed || type === 'company'" class="address-form__line">
          <SfFormInput type="text" :label="labels.company" :name="prefix('company')" :required="typed && type === 'company'" :readonly="readonly" />
        </div>
      </template>
      <div v-if="isImplicitAddress" class="address-form__line">
        <div class="address-form__street">
          <SfFormInput type="text" :label="labels.street" :name="prefix('street')" required :readonly="readonly" />
        </div>
        <div class="address-form__building-nr">
          <SfFormInput type="text" :label="labels.building_nr" :name="prefix('building_nr')" required :readonly="readonly" />
        </div>
        <div class="address-form__apartment-nr">
          <SfFormInput type="text" :label="labels.apartment_nr" :name="prefix('apartment_nr')" :readonly="readonly" />
        </div>
      </div>
      <div v-if="!isImplicitAddress" class="address-form__line">
        <SfFormInput type="text" :label="labels.first_line" :name="prefix('first_line')" required :readonly="readonly" />
      </div>
      <div v-if="!isImplicitAddress" class="address-form__line">
        <SfFormInput type="text" :label="labels.second_line" :name="prefix('second_line')" :readonly="readonly" />
      </div>
      <div class="address-form__line">
        <div class="address-form__postcode">
          <SfFormInput type="text" :label="labels.postcode" :name="prefix('postcode')" required :readonly="readonly" />
        </div>
        <div class="address-form__city">
          <SfFormInput type="text" :label="labels.city" :name="prefix('city')" required :readonly="readonly" />
        </div>
      </div>
      <div class="address-form__line">
        <SfFormInput type="text" :label="labels.region" :name="prefix('region')" :readonly="readonly" />
      </div>
      <div v-if="billable && (!typed || type === 'company')" class="address-form__line">
        <SfFormInput type="text" :label="labels.vat_id" :name="prefix('vat_id')" required :readonly="readonly" />
      </div>
      <div v-if="contactable" class="address-form__line">
        <SfFormInput type="email" :label="labels.email" :name="prefix('email')" :required="contactRequired" :readonly="readonly" />
      </div>
      <div v-if="contactable" class="address-form__line">
        <SfFormInput type="tel" :label="labels.phone" :name="prefix('phone')" :required="contactRequired" :readonly="readonly" />
      </div>
    </template>
  </section>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .address-form {
    display: flex;
    flex-direction: column;
    gap: var(--form-gap-y) var(--form-gap-x);

    &__line {
      display: flex;
      flex-direction: row;
      gap: var(--form-gap-y) var(--form-gap-x);
    }

    &__street {
      flex: 1 1 auto;
    }

    &__type {
      margin-bottom: 1rem;
    }

    &__building-nr,
    &__apartment-nr {
      flex: 0 0 128px;
    }

    &__postcode {
      flex: 0 0 208px;
    }

    &__city {
      flex: 1 1 auto;
    }
  }

  @include media.query(md) {
    .address-form {
      &__line {
        flex-direction: column;
      }

      &__building-nr,
      &__apartment-nr,
      &__postcode {
        flex: 1 1 auto;
      }
    }
  }
</style>
