<script setup lang="ts">
  import { type PropType, computed } from 'vue';

  const props = defineProps({
    segments: { type: Array as PropType<any[]>, required: true },
  });

  const normalized = computed(() => {
    return props.segments.filter((s) => !!s).join(', ');
  });
</script>
<template>
  <address v-text="normalized" />
</template>
