<script lang="ts" setup>
  import { $patch, route, useSession } from '@plenny/visitor';
  import { useCart } from '$storefront';
  import { CartLayout } from '$components';

  defineOptions({
    layout: CartLayout,
    inheritAttrs: false,
  });

  const props = defineProps({
    offer: { type: Boolean, required: true },
  });

  const session = useSession();
  const { cart } = useCart();

  function onSubmit(data) {
    if (props.offer) {
      return $patch(route('web.offer.details.update'), data);
    } else {
      return $patch(route('web.cart.details.update'), data);
    }
  }
</script>
<template>
  <VisitorForm v-slot="{ data, submitting }" :raw="cart" @submit="onSubmit">
    <div class="order">
      <div class="order-details">
        <div class="order-details__column order-details__column--left">
          <h1 class="order-details__heading">
            {{ offer ? $t('Finalizacja zapytania') : $t('Finalizacja') }}
          </h1>
          <SfTile id="shipping-method" class="order-details__tile order-details__shipping-method" numeric>
            <template #outer>
              <div class="order-details__section-header">
                <span class="order-details__number" />
                <h2 class="order-heading">{{ $t('Sposób dostawy') }}</h2>
              </div>
            </template>
            <template #default>
              <OrderFormShippingMethod />
            </template>
          </SfTile>
          <SfTile id="billing-data" class="order-details__tile order-details__billing-data" numeric>
            <template #outer>
              <div class="order-details__section-header">
                <span class="order-details__number" />
                <h2 class="order-heading">{{ $t('Dane kupującego') }}</h2>
              </div>
            </template>
            <template #default>
              <OrderFormBillingData />
            </template>
          </SfTile>
          <SfTile id="shipping-data" class="order-details__tile order-details__shipping-data" numeric>
            <template #outer>
              <div class="order-details__section-header">
                <span class="order-details__number" />
                <h2 class="order-heading">{{ $t('Adres dostawy') }}</h2>
              </div>
            </template>
            <template #default>
              <OrderFormShippingData />
            </template>
          </SfTile>
          <SfTile v-if="data.total_gross != 0" id="billing-method" class="order-details__tile order-details__billing-method" numeric>
            <template #outer>
              <div class="order-details__section-header">
                <span class="order-details__number" />
                <h2 class="order-heading">{{ $t('Sposób płatności') }}</h2>
              </div>
            </template>
            <template #default>
              <OrderFormBillingMethod :offer="offer" />
            </template>
          </SfTile>
          <SfTile id="note" class="order-details__tile order-details__customer-note" numeric>
            <template #outer>
              <div class="order-details__section-header">
                <span class="order-details__number" />
                <h2 class="order-heading">{{ $t('Komentarz do zamówienia') }}</h2>
              </div>
            </template>
            <template #default>
              <OrderFormCustomerNote />
            </template>
          </SfTile>
          <SfTile id="conditions" class="order-details__tile order-details__conditions" numeric>
            <template #outer>
              <div class="order-details__section-header">
                <span class="order-details__number" />
                <h2 class="order-heading">{{ $t('Regulamin') }}</h2>
              </div>
            </template>
            <template #default>
              <OrderFormConditions />
            </template>
          </SfTile>
        </div>
        <div class="order-details__column order-details__column--right">
          <SfTile class="order-details__summary" number>
            <template #outer>
              <h2 class="order-heading">
                {{ offer ? $t('Twoje zapytanie') : $t('Twoje zamówienie') }}
              </h2>
            </template>
            <div class="order-details__products">
              <OrderSummaryItems v-for="item in cart.items" :key="item.id" :item="item" />
            </div>
            <div class="order-details__options-summary">
              <OrderSummaryShippingOption class="order-details__option-summary" />
              <OrderSummaryBillingOption class="order-details__option-summary" />
              <OrderSummaryRealizationTime class="order-details__option-summary" />
            </div>
            <OrderSummary :order="cart">
              <SfButton :loading="submitting" primary type="submit">
                {{ $t('Przejdź do podsumowania') }}
              </SfButton>
            </OrderSummary>
            <div v-if="session.user && session.user.credit_limit > 0" class="customer-credit">
              <h2>Kredyt kupiecki</h2>
              <dl>
                <dt>
                  {{ $t('Obecne zadłużenie:') }}
                </dt>
                <dd>
                  <SfMoney :value="session.user.credit" />
                </dd>
                <dt>
                  {{ $t('Limit zadłużenia:') }}
                </dt>
                <dd>
                  <SfMoney :value="session.user.credit_limit" />
                </dd>
              </dl>
            </div>
          </SfTile>
        </div>
      </div>
    </div>
  </VisitorForm>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .order-details {
    display: grid;
    grid-template-columns: 2fr minmax(450px, 1fr);
    gap: 3rem;

    &__section-header {
      display: flex;
      align-items: center;
      column-gap: 0.625rem;
    }

    &__number {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      width: 2.5rem;
      font-size: 1.5rem;
      font-weight: var(--font-weight-bold);
      line-height: 1;
      padding: 0.375rem;
      border-radius: 50%;
      border-style: var(--sf-order-details-number-border-style);
      border-color: var(--sf-order-details-number-border-color);
      border-width: var(--sf-order-details-number-border-width);
      counter-increment: list;

      &::after {
        content: counter(list)
      }
    }

    &__column--left {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      counter-reset: list;
    }

    &__column--right {
      .customer-credit {
        font-size: 0.875rem;
        border: 1px solid transparent;

        h2 {
          font-size: 1.25rem;
          margin-bottom: 1rem;
        }

        dl {
          display: grid;
          grid-template-columns: auto 1fr;
          column-gap: 1rem;
          row-gap: 0.25rem;

          dd {
            font-weight: bold;
          }
        }
      }
    }

    &__summary {
      --sf-tile-gap: 2rem;
      position: sticky;
      top: var(--sf-order-cart-summary-sticky-top);
    }

    &__products {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
    }

    &__options-summary {
      display: flex;
      flex-direction: column;
      border-radius: var(--sf-order-details-options-summary-border-radius);
      background-color: var(--sf-order-details-options-summary-bg);
    }

    &__option-summary {
      &:first-child {
        padding-top: 1rem;
      }

      &:last-child {
        padding-bottom: 1rem;
      }
    }
  }

  @include media.query(xl) {
    .order-details {
      grid-template-columns: 1fr;
      margin: 0;
      gap: 1rem;

      &__column--right {
        margin-top: 1rem;
        position: static;
      }

      &__heading {
        margin-top: 20px;
      }
    }
  }

  @include media.query(lg) {
    .order-details {
      margin: 0;
      gap: 10px;

      .order-heading {
        font-size: 1.25rem;
      }

      &__tile {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      &__number {
        height: 2rem;
        width: 2rem;
        font-size: 1.25rem;
      }

      &__column--left {
        gap: 1rem;
      }

      &__heading {
        font-size: 25px;
        margin-top: 0;
        padding-left: 10px;
      }

      &__summary {
        gap: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
</style>
