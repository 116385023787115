<script lang="ts" setup>
  import { type PropType } from 'vue';
  import { useBreakpoints } from '$storefront';
  import { trans } from '@plenny/translator';
  import { $get, route } from '@plenny/visitor';
  import type { Pagination } from '$types';

  interface RelatedDocument {
    type: number,
    documentId: number,
    documentDefinition: string,
    documentDate: string,
    documentNumber: string,
    numberExternal: string,
  }

  type Settlement = {
    documentType: string;
    documentSymbol: string;
    documentDate: string;
    dateOfPayment: string;
    currency: string;
    total: number;
    paid: number;
    leftToPay: number;
    isOverdue: boolean;
    download: string | null;
    settled: boolean;
    relations: RelatedDocument[];
  }

  defineOptions({
    layout: ['StoreLayout', 'AccountLayout'],
    inheritAttrs: false,
  });

  defineProps({
    settlements: { type: Object as PropType<Pagination<Settlement>>, required: true },
    filters: { type: Object as PropType<any>, required: true },
    credit: { type: Number, required: true },
    debit: { type: Number, required: true },
    creditInRange: { type: Number, required: true },
    debitInRange: { type: Number, required: true },
  });

  const breakpoints = useBreakpoints();

  const settledOptions = [
    { value: false, label: trans('Nie') },
    { value: true, label: trans('Tak') },
  ];

  const settlementTypeOptions = [
    { value: true, label: trans('Zobowiązania') },
    { value: false, label: trans('Należności') },
  ];

  function handleFilters(data) {
    return $get(route('web.account.settlements.index', data));
  }
</script>
<template>
  <AccountMobileNavigation :href="route('web.account.show')">
    {{ $t('Rozrachunki') }}
  </AccountMobileNavigation>
  <SfTile large class="account-settlements">
    <template #title v-if="breakpoints.lg">
      <h1 class="account__heading">{{ $t('Rozrachunki') }}</h1>
    </template>
    <template #default>
      <div class="settlement-summary">
        <div class="item">
          <h2>Łącznie zobowiązania:</h2>
          <SfMoney :value="credit" />
        </div>
        <div class="item">
          <h2>Łącznie należności:</h2>
          <SfMoney :value="debit" />
        </div>
      </div>
      <div class="account-settlements-filters">
        <VisitorForm ref="form" :initial="filters" @submit="handleFilters">
          <div class="col">
            <SfFormRadio :label="$t('Rodzaj:')" name="type" :options="settlementTypeOptions" @change="$refs.form.submit()" />
          </div>
          <div class="col">
            <SfFormRadio :label="$t('Rozliczone:')" name="settled" :options="settledOptions" @change="$refs.form.submit()" />
          </div>
          <div class="col">
            <SfFormInput :label="$t('Od:')" name="from" type="date" @change="$refs.form.submit()" />
          </div>
          <div class="col">
            <SfFormInput :label="$t('Do:')" name="to" type="date" @change="$refs.form.submit()" />
          </div>
        </VisitorForm>
      </div>
      <div v-if="settlements.data.length > 0" class="account-settlements__content">
        <SfListCard v-for="settlement in settlements.data" :key="settlement.documentSymbol" class="account-settlements">
          <template #icons>
            <span class="account-settlements__count" :class="{ 'account-settlements__overdue': !settlement.settled && settlement.isOverdue }">
              {{ $t('termin płatności:') }}
              <SfDateTime :value="settlement.dateOfPayment" />
            </span>
          </template>
          <template #default>
            <div class="account-settlements__header">
              <h2 class="account-settlements__heading">{{ settlement.documentType }} {{ settlement.documentSymbol }}</h2>
              <p class="account-settlements__date">
                {{ $t('z dnia') }}
                <SfDateTime :value="settlement.documentDate" />
              </p>
            </div>
            <div class="account-settlements__details">
              <span v-for="related in settlement.relations" class="account-settlements__related">
                <span>
                  <small>{{ related.type === 1 ? $t('Korekta do:') : $t('Dotyczy:') }}</small>
                </span>&nbsp;
                <span>
                  {{ related.documentDefinition }} {{ related.numberExternal }}
                </span>&nbsp;
                <span>
                  <small>{{ $t('z dnia') }}</small>
                </span>&nbsp;
                <SfDateTime :value="related.documentDate" />
              </span>
              <span class="account-settlements__separator" />
              <span class="account-settlements__price">
                <span>
                  <small>Rozliczono:</small>
                </span>&nbsp;
                <SfMoney :code="settlement.currency" :value="settlement.paid" />&nbsp;
                <span>
                  <small>z</small>
                </span>&nbsp;
                <SfMoney :code="settlement.currency" :value="settlement.total" />
              </span>
              <span class="account-settlements__price">
                <span>
                  <small>Pozostało:</small>
                </span>&nbsp;
                <SfMoney :code="settlement.currency" :value="settlement.leftToPay" />
              </span>
            </div>
          </template>
          <template #actions>
            <SfButton :href="settlement.download || ''" :disabled="!settlement.download" small target="_blank">
              {{ settlement.download ? $t('Pobierz dokument') : $t('Dokument niedostępny') }}
            </SfButton>
          </template>
        </SfListCard>
      </div>
      <SfDataPlaceholder v-else>
        {{ $t('Brak danych do wyświetlenia.') }}
      </SfDataPlaceholder>
      <div class="settlement-summary-small">
        <div class="item label">
          <h2>W wybranym zakresie:</h2>
        </div>
        <div class="item">
          <h3>Zobowiązania:</h3>
          <SfMoney :value="creditInRange" />
        </div>
        <div class="item">
          <h3>Należności:</h3>
          <SfMoney :value="debitInRange" />
        </div>
      </div>
      <SfPagination :pagination="settlements" />
    </template>
  </SfTile>
</template>

<style scoped lang="scss">
  @use "$assets/mixins/media";

  .account-settlements-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
  }

  .settlement-summary {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4rem;
    row-gap: 1rem;

    .item {
      font-size: 3rem;
      font-weight: var(--font-weight-bold);

      h2 {
        font-size: 0.875rem !important;
      }
    }
  }

  .settlement-summary-small {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4rem;
    row-gap: 1rem;
    line-height: 1.25rem;

    .item {
      display: flex;
      font-size: 1rem;
      font-weight: var(--font-weight-bold);
      gap: 1rem;

      &.label {
        margin-right: auto;
      }

      h2, h3 {
        font-size: 0.875rem !important;
      }

      h3 {
        font-weight: var(--font-weight-regular);
      }
    }
  }

  .account-settlements {
    .list-card__content-default {
      gap: 0;
    }

    &__content {
      display: flex;
      flex-direction: column;
      row-gap: 1.25rem;
    }

    &__details {
      display: flex;
      flex-direction: column;

      small {
        font-weight: var(--font-weight-regular);
      }
    }

    &__header {
      display: flex;
      align-items: center;
      column-gap: 0.625rem;
    }

    &__heading {
      font-size: 1.25rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__date,
    &__count {
      font-size: 0.875rem;
      color: var(--sf-account-color-dark-grey);
    }

    &__overdue {
      font-weight: var(--font-weight-bold);
      color: var(--danger-color);
    }

    &__related {
      font-weight: var(--font-weight-bold);
      margin-top: 0.125rem;
      font-size: 0.875rem;
    }

    &__separator {
      display: block;
      margin: 0.5rem 0;
      background: var(--secondary-border);
      height: 1px;
      width: 100%;
    }

    &__price {
      font-weight: var(--font-weight-bold);
      margin-top: 0.125rem;
    }
  }

  @include media.query(lg) {
    .account-settlements {
      row-gap: 1rem;

      &__content .list-card__actions {
        gap: 0.75rem;
      }
    }
  }
</style>
