<script setup lang="ts">
  import { computed, type PropType } from 'vue';
  import { Features } from '$features';
  import { useSession, useShared } from '@plenny/visitor';

  interface HasCatalogPrice {
    catalog_net: string;
    catalog_tax: string;
    catalog_gross: string;
    catalog_discount: string;
  }

  const props = defineProps({
    item: { type: Object as PropType<HasCatalogPrice>, required: true },
  });

  const shared = useShared();
  const session = useSession();

  const catalog = computed(() => {
    if (Features.streamsoftContractorPrices.isEnabled() && session.value.user && session.value.user.b2b) {
      if (props.item.catalog_discount > 0) {
        if (shared.value.include_vat) {
          return { price: props.item.catalog_gross, discount: props.item.catalog_discount * 100 };
        } else {
          return { price: props.item.catalog_net, discount: props.item.catalog_discount * 100 };
        }
      }
    }
  });
</script>
<template>
  <div v-if="catalog" class="product-catalog">
    <span>
      {{ $t('Cena katalogowa:') }}
      <SfMoney :value="catalog.price" class="product-catalog-price" />
    </span>
    <span class="product-catalog-discount">
      <SfFormattedPercentage :value="catalog.discount" />
    </span>
  </div>
</template>
<style scoped lang="scss">
  .product-catalog {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    align-items: center;
    column-gap: 0.5rem;
    font-size: 0.75rem;
    margin: 0.25rem 0;
    text-align: right;
    color: var(--color-default);

    .product-catalog-price {
      font-weight: var(--font-weight-700);
    }

    .product-catalog-discount {
      display: inline-block;
      padding: 0.125rem 0.25rem;
      font-weight: var(--font-weight-700);
      background: var(--primary-color);
      color: var(--primary-foreground);
    }
  }
</style>
