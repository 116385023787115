<script setup lang="ts">
  import { ref } from 'vue';

  const open = defineModel('open', { type: Boolean, required: false, default: false });

  const props = defineProps({
    product: { type: Object, required: true },
    variant: { type: Object, required: false, default: undefined },
    related: { type: Array, required: false, default: [] },
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 1.5,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2,
      },
    },
  });
</script>
<template>
  <SfModal v-model:open="open" class="cart-button-success-modal">
    <SfModalHeader v-model:open="open">
      {{ $t('Dodano do koszyka') }}
    </SfModalHeader>

    <section class="cart-button-success-modal__product">
      <div class="cart-button-success-modal__photo">
        <MediaPicture :photo="variant?.photo || product.photo" sizes="200x200 2x, 100x100" />
      </div>
      <div class="cart-button-success-modal__details">
        <h3 class="cart-button-success-modal__details-title">{{ variant?.title || product.title }}</h3>
        <SfProductCatalogPrice v-if="variant" :item="variant" />
        <SfProductPrice v-if="variant" :price="variant" />
        <SfProductOmnibus v-if="variant" :price="variant" />
      </div>
    </section>

    <section v-if="related.length > 0" class="cart-button-success-modal__related">
      <header>
        <h2 class="cart-button-success-modal__related-header">
          {{ $t('Mogą Ci się przydać...') }}
        </h2>
      </header>
      <SfProductsCarousel :products="related" :parameters />
    </section>

    <SfModalFooter>
      <SfButton @click="open = false">
        {{ $t('Kontynuuj zakupy') }}
      </SfButton>
      <SfButton :href="route('web.cart.show')" primary>
        {{ $t('Przejdź do koszyka') }}
      </SfButton>
    </SfModalFooter>
  </SfModal>
</template>

<style lang="scss">
  @use "$assets/mixins/media";

  .cart-button-success-modal {

    &__product {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
    }

    &__photo {
      flex: 0 0 100px;
    }

    &__details {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__details-title {
      font-size: 1.25rem;
    }

    &__related header {
      margin: 1.25rem 0 0.625rem;
    }

    &__related-header {
      font-size: 1.125rem;
    }
  }

  @include media.query(lg) {
    .cart-button-success-modal {
      &__product,
      &__product .cart-button-success-modal__details {
        gap: 0.5rem;
      }

      &__details-title {
        font-size: 1rem;
      }
    }
  }
</style>
