<script setup lang="ts">
  import { $patch, route, $get, url, useQuery, useShared } from '@plenny/visitor';
  import { ref, computed } from 'vue';
  import { trans } from '@plenny/translator';

  const emit = defineEmits(['onFiltersOpen']);

  const query = useQuery();
  const shared = useShared();

  const processing = ref(false);
  const popover = ref();
  const type = computed(() => shared.value.product_list_mode);

  const sorting = [
    { label: trans('Nazwa alfabetycznie'), value: 'name' },
    { label: trans('Cena: od najtańszych'), value: 'cheap' },
    { label: trans('Cena: od najdroższych'), value: 'expensive' },
    { label: trans('Ocena: od najlepszej'), value: 'rating' },
  ];

  const items = [
    { label: trans('12'), value: 12 },
    { label: trans('20'), value: 20 },
    { label: trans('28'), value: 28 },
    { label: trans('36'), value: 36 },
    { label: trans('48'), value: 48 },
  ];

  const available = computed({
    get: () => {
      return query.value.available === undefined || query.value.available === '1';
    },
    set: (value) => {
      processing.value = true;

      let filtered = Object.fromEntries(Object.entries(query.value).filter(([name, value]) => {
        return name !== 'available' && !!value;
      }));

      if (value !== undefined) {
        filtered.available = value;
      }

      $get(url(window.location.pathname, filtered)).then(() => {
        processing.value = false;
      });
    },
  });

  const sort = computed({
    get: () => {
      return query.value.sort;
    },
    set: (value) => {
      processing.value = true;

      let filtered = Object.fromEntries(Object.entries(query.value).filter(([name, value]) => {
        return name !== 'sort' && !!value;
      }));

      if (value) {
        filtered.sort = value;
      }

      $get(url(window.location.pathname, filtered)).then(() => {
        processing.value = false;
      });
    },
  });

  const perPage = computed({
    get: () => {
      return shared.value.product_list_per_page;
    },
    set: (value) => {
      processing.value = true;

      $patch(route('api.v1.web.product.list.per_page'), { per_page: value }).then(() => {
        let filtered = Object.fromEntries(Object.entries(query.value).filter(([name, value]) => {
          return name !== 'page' && !!value;
        }));

        $get(url(window.location.pathname, { ...filtered })).then(() => {
          processing.value = false;
        });
      });
    },
  });

  function actionToggleMode() {
    let mode = (type.value === 1) ? 0 : 1;

    processing.value = true;

    $patch(route('api.v1.web.product.list.mode'), { mode }).then(() => {
      processing.value = false;
    });
  }

  function actionOpenFilters() {
    emit('onFiltersOpen');
  }
</script>
<template>
  <div class="products-top-panel">
    <div class="products-top-panel__icons">
      <SfButton :loading="processing" square transparent @click="actionToggleMode">
        <template #before>
          <SfIconShopTile v-if="type === 1" />
          <SfIconShopList v-if="type === 0" />
        </template>
      </SfButton>
      <SfButton :loading="processing" square transparent :title="available ? $t('Pokazuj tylko dostępne produkty') : $t('Pokazuj wszystkie produkty')" @click="available = !available">
        <template #before>
          <SfIconBoxSeam v-if="!available" class="availability-icon-unavailable" />
          <SfIconBoxSeamFill v-if="available" class="availability-icon-available" />
        </template>
      </SfButton>
    </div>
    <div class="products-top-panel__actions">
      <div id="products-top-panel-popover" ref="popover" class="products-top-panel__popover" popover>
        <SfButton class="products-top-panel__popover-close" small transparent @click="popover.hidePopover()">
          <SfIconClose />
        </SfButton>
        <div class="products-top-panel__per-page">
          <SfFormSelect v-model="perPage" :disabled="processing" :label="$t('Ilość na stronie')" :options="items" :placeholder="false" />
        </div>
        <SfFormSelect v-model="sort" :disabled="processing" :label="$t('Sortowanie')" :options="sorting" :placeholder="$t('Domyślne')" />
      </div>
      <SfButton class="products-top-panel__filter" :title="$t('Filtry')" primary square @click="actionOpenFilters" @keydown.enter="actionOpenFilters">
        <template #before>
          <SfIconFunnel />
        </template>
      </SfButton>
      <SfButton class="products-top-panel__filter" :title="$t('Sortuj')" square popovertarget="products-top-panel-popover">
        <template #before>
          <SfIconSortAlphaDown />
        </template>
      </SfButton>
    </div>
  </div>
</template>
<style scoped lang="scss">
  @use "$assets/mixins/media";

  .products-top-panel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--gap-card);
    background: var(--background-card);
    padding: var(--padding-card);
    border-radius: var(--border-radius);

    @include media.query(lg) {
      flex-direction: row-reverse;
    }

    &__icons {
      display: flex;
      gap: 0.25rem;

      .availability-icon-available {
        color: var(--primary-color);
      }
    }

    &__actions {
      display: flex;
      position: relative;
      width: 100%;
      gap: 0.25rem;

      @include media.query(lg) {
        position: unset;
      }
    }

    &__popover {
      position: absolute;
      display: flex;
      gap: 2rem;
      border: none;
      transform: translate(-100%, -50%);
      justify-content: flex-end;
      left: 100%;
      width: auto;
      min-width: 50%;
      transition: all linear 0.2s;
      background-color: transparent;
      overflow: visible;

      @include media.query(xl) {
        width: 75%;
      }

      @include media.query(lg) {
        position: fixed;
        width: 100%;
        transform: translateY(100%);
        top: 100%;
        left: 0;
        bottom: 0;
        display: none;
        flex-direction: column;
        gap: 1rem;
        background: var(--background-popover);
        padding: 3.5rem 1rem 2rem 1rem;
        overflow: auto;

        &:popover-open {
          animation: slideIn 0.2s ease-in-out both;
          display: flex;

          &::backdrop {
            animation: darken 0.2s ease-in-out both;
          }
        }
      }
    }

    &__popover-close {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 1.5rem;
      display: none;

      @include media.query(lg) {
        display: block;
      }
    }

    &__per-page {
      flex: 0 0 9rem;
      width: 100%;

      @include media.query(lg) {
        flex: 1 1 auto;
        width: 100%;
      }
    }

    &__filter {
      display: none;

      @include media.query(lg) {
        display: inline-flex;
      }
    }

    &__icons {
      background-color: inherit;
      min-width: 5.5rem;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(-100%);
    }
  }

  @keyframes darken {
    from {
      background: rgba(0, 0, 0, 0);
    }
    to {
      background: rgba(0, 0, 0, 0.5);
    }
  }
</style>
