<script setup lang="ts">
  import { ref, type PropType } from 'vue';

  interface OrderPackage {
    order_id: number,
    tracking_number: string,
    state: string | null,
    status: string | null,
    status_at: string | null,
    branch: string | null,
    locate: string | null,
    tracking: OrderPackageTracking[],
  }

  interface OrderPackageTracking {
    state: string,
    status: string,
    datetime: string,
    branch: string,
  }

  defineProps({
    shipment: { type: Object as PropType<OrderPackage>, required: true },
  });

  const open = ref(false);
</script>
<template>
  <div class="shipment">
    <div class="summary" @click="open = !open">
      <div class="tracking-number">{{ shipment.tracking_number }}</div>
      <div class="tracking-status">{{ shipment.status }}</div>
      <div class="tracking-place">
        <div class="tracking-branch" v-if="shipment.branch">{{ shipment.branch }}</div>
        <SfDateTime class="tracking-date" v-if="shipment.status_at" :value="shipment.status_at" time />
      </div>
    </div>
    <ul v-if="open">
      <li v-for="tracking in shipment.tracking">
        <span class="tracking-status">{{ tracking.status }}</span>
        <span class="tracking-place">
          <span class="tracking-branch" v-if="tracking.branch">{{ tracking.branch }}</span>
          <SfDateTime class="tracking-date" v-if="tracking.datetime" :value="tracking.datetime" time />
        </span>
      </li>
    </ul>
  </div>
</template>
<style scoped lang="scss">
  .shipment {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--secondary-border);
    min-width: 0;
    max-width: 100%;
    padding: 0.375rem;
    gap: 0.5rem;

    .summary {
      line-height: 0.875rem;
      font-size: 0.75rem;
      cursor: pointer;

      .tracking-number {
        display: block;
        font-size: 1rem;
        line-height: 1rem;
        font-weight: var(--font-weight-bold);
        text-overflow: ellipsis;
        overflow: hidden;
        min-width: 0;
        max-width: 100%;
      }

      .tracking-place {
        color: #00000088;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      border-left: 0.125rem dotted var(--secondary-border);
      padding-left: 1rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      gap: 0.5rem;
      width: 100%;
      min-width: 0;
      max-width: 100%;

      li {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        column-gap: 0.5rem;
        row-gap: 0.125rem;
        width: 100%;
        min-width: 0;
        max-width: 100%;

        &:before {
          content: "";
          display: block;
          position: absolute;
          background: var(--primary-background);
          border-radius: 0.25rem;
          height: 0.5rem;
          width: 0.5rem;
          left: -1.3125rem;
          top: 0.375rem;
        }

        .tracking-place {
          color: #00000088;
          font-size: 0.75rem;
          display: flex;
          flex-wrap: wrap;
          column-gap: 0.5rem;
          row-gap: 0.125rem;
        }
      }

    }
  }
</style>
