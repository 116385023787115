<script setup lang="ts">
  import { route, useSession } from '@plenny/visitor';
  import { computed, ref } from 'vue';
  import { useCart, useBreakpoints, useOffersPermissions, useOrdersPremissions } from '$storefront';

  const { items, count, cart } = useCart();
  const { canRequestOffers } = useOffersPermissions();
  const { canPlaceOrders } = useOrdersPremissions();

  const session = useSession();
  const breakpoints = useBreakpoints();

  const active = ref();

  const empty = computed(() => {
    return count.value === 0;
  });

  function toggle(section: string) {
    if (active.value !== section) {
      active.value = section;
    } else {
      active.value = null;
    }
  }
</script>
<template>
  <div class="order order-cart ">
    <div v-if="!empty" class="order-cart__content">
      <div class="order-cart__column">
        <SfTile>
          <template #outer>
            <h1 class="order-heading">
              {{ $t('Koszyk') }}
            </h1>
            <div class="order-cart__count">
              {{ $tc(':count produkt|:count produkty|:count produktów', count, { count }) }}
            </div>
            <div class="order-cart__buttons">
              <CartEmptyButton :isCartEmpty="empty" />
              <CartWishlistButton :isCartEmpty="empty" />
              <CartImportButton />
            </div>
          </template>
          <template #default>
            <div class="order-cart__products">
              <CartItem v-for="item in items" :key="item.id" :item="item" />
            </div>
          </template>
        </SfTile>
        <div class="order-cart__warning">
          <SfIconInfo />
          {{ $t('Nie zwlekaj z zakupem, dodanie artykułów do koszyka nie oznacza ich rezerwacji.') }}
        </div>
      </div>
      <div class="order-cart__column order-cart__column--right">
        <div class="order-cart__summary">
          <SfTile>
            <template #outer v-if="breakpoints.xl">
              <h2 class="order-heading" :class="{'hide': !breakpoints.xl }" :aria-hidden="!breakpoints.xl">
                {{ $t('Podsumowanie') }}
              </h2>
            </template>
            <template #default>
              <OrderSummary :order="cart">
                <div class="order-cart__actions">
                  <SfButton v-if="canPlaceOrders" :disabled="empty" primary :href="route('web.cart.account.show')">
                    {{ $t('Przejdź do kasy') }}
                  </SfButton>
                  <SfButton v-if="canRequestOffers" :disabled="empty" :href="route('web.offer.account.show')">
                    {{ $t('Zapytaj o ofertę') }}
                  </SfButton>
                </div>
                <div class="order-cart__discount">
                  <CartLoyaltyPoints v-if="session.is_authenticated && $settings.FeatureLoyaltyEnabled" :open="active === 'loyalty'" @toggle="toggle" />
                  <CartDiscountCode :open="active === 'discount'" @toggle="toggle" />
                </div>
              </OrderSummary>
              <div class="order-cart__warning">
                <SfIconInfo />
                {{ $t('Nie zwlekaj z zakupem, dodanie artykułów do koszyka nie oznacza ich rezerwacji.') }}
              </div>
            </template>
          </SfTile>
          <div v-if="session.user && session.user.credit_limit > 0" class="customer-credit">
            <h2>Kredyt kupiecki</h2>
            <dl>
              <dt>
                {{ $t('Obecne zadłużenie:') }}
              </dt>
              <dd>
                <SfMoney :value="session.user.credit" />
              </dd>
              <dt>
                {{ $t('Limit zadłużenia:') }}
              </dt>
              <dd>
                <SfMoney :value="session.user.credit_limit" />
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="order-cart__empty">
      <SfDataPlaceholder>
        {{ $t('Brak produktów w koszyku.') }}
      </SfDataPlaceholder>
      <SfButton primary :href="route('web.homepage.index')">
        {{ $t('Strona główna') }}
      </SfButton>
    </div>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .order-cart {
    --sf-data-placeholder-font-size: 1.25rem;
    --sf-data-placeholder-font-weight: var(--font-weight-semi);
    max-width: var(--sf-order-cart-max-width);
    min-height: unset;

    &__empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      padding: 4rem 0;
    }

    &__content {
      display: grid;
      grid-template-columns: 3fr minmax(400px, 1fr);
      gap: 2rem;
    }

    &__products {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &__count {
      display: inline-block;
      font-size: 0.875rem;
      font-weight: var(--font-weight-semi);
      padding: 0.25rem 0.5rem;
      background-color: var(--sf-order-cart-count-background);
      border-radius: var(--sf-order-cart-count-border-radius);
      white-space: nowrap;
    }

    &__warning {
      display: flex;
      column-gap: 0.5rem;
      align-items: center;
      font-size: 0.875rem;
      color: var(--sf-order-cart-warning-color);
      margin-top: 1rem;
    }

    &__summary {
      position: sticky;
      top: var(--sf-order-cart-summary-top);

      .customer-credit {
        font-size: 0.875rem;
        border: 1px solid transparent;
        padding: 2rem;

        h2 {
          font-size: 1.25rem;
          margin-bottom: 1rem;
        }

        dl {
          display: grid;
          grid-template-columns: auto 1fr;
          column-gap: 1rem;
          row-gap: 0.25rem;

          dd {
            font-weight: bold;
          }
        }
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    &__discount {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin: 2rem 0 0.5rem;
    }

    &__column--right .order-cart__warning {
      display: none
    }
  }

  @include media.query(xl) {
    .order-cart {
      &__content {
        grid-template-columns: 1fr;
        gap: 1rem;
        width: 100%;
      }

      &__warning {
        display: none;
      }

      &__column--right .order-cart__warning {
        display: flex;
        align-items: flex-start;

        svg {
          margin-top: 0.125rem;
        }
      }

      &__products {
        gap: 1.25rem;
      }

      &__buttons {
        display: flex;
        flex-wrap: wrap;
        column-gap: 0.875rem;
        width: 100%;
      }

      .tile__content {
        padding-top: 1rem;
      }
    }
  }
</style>
