<script lang="ts" setup>
  import { type PropType, computed } from 'vue';
  import { useShared } from '@plenny/visitor';
  import { UnitFormatter } from '$support';
  import { Unit } from '$types';
  import { trans } from '@plenny/translator';

  type Priceable = {
    price_net: string,
    price_gross: string,
    regular_net: string,
    regular_gross: string,
    stock_unit: Unit,
    stock_unit_base: string,
  }

  const props = defineProps({
    price: { type: Object as PropType<Priceable>, required: true },
    presentation: { type: Boolean, required: false, default: false },
    current: { type: Boolean, required: false, default: false },
    small: { type: Boolean, required: false, default: false },
    row: { type: Boolean, required: false, default: false },
  });

  const shared = useShared();

  const priceClasses = computed(() => {
    return {
      'price--presentation': props.presentation,
      'price--small': props.small,
      'price--row': props.row,
    };
  });

  const regular = computed(() => {
    if (shared.value.include_vat) {
      return props.price.regular_gross;
    } else {
      return props.price.regular_net;
    }
  });

  const price = computed(() => {
    if (shared.value.include_vat) {
      return props.price.price_gross;
    } else {
      return props.price.price_net;
    }
  });

  const promotion = computed(() => {
    return !props.current && price.value != regular.value;
  });

  const unit = computed(() => {
    let stockUnit = props.price.stock_unit;
    let stockBase = props.price.stock_unit_base;

    if (stockBase === '1.0000') {
      return UnitFormatter.symbol(stockUnit);
    }

    return UnitFormatter.short(stockUnit, stockBase);
  });

  const message = computed(() => {
    if (shared.value.include_vat) {
      return trans('brutto&nbsp;/&nbsp;:unit', { unit: unit.value });
    } else {
      return trans('netto&nbsp;/&nbsp;:unit', { unit: unit.value });
    }
  });
</script>
<template>
  <div :class="priceClasses" class="price">
    <SfMoney v-if="promotion" :value="regular" class="price__catalog" />
    <div v-if="price" class="price__current">
      <SfMoney :value="price" />
      <span class="price__per" v-html="message" />
    </div>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-product-price-color: var(--color-default);
    --sf-product-price-font-family: var(--font-family-sans);

    --sf-product-price-current-font-size: 1rem;
    --sf-product-price-current-font-line-height: 1.25rem;
    --sf-product-price-current-font-weight: var(--font-weight-bold);
    --sf-product-price-current-color: var(--color-default);

    --sf-product-price-per-font-size: 0.625rem;
    --sf-product-price-per-font-line-height: 0.625rem;
    --sf-product-price-per-font-weight: var(--font-weight-regular);
    --sf-product-price-per-color: var(--color-default);

    --sf-product-price-catalog-font-size: 0.75rem;
    --sf-product-price-catalog-font-line-height: 0.75rem;
    --sf-product-price-catalog-font-weight: var(--font-weight-regular);
    --sf-product-price-catalog-color: var(--danger-color);

    // modifiers

    --sf-product-price-presentation-gap: 0.75rem;

    --sf-product-price-presentation-catalog-font-size: var(--font-size-sm);
    --sf-product-price-presentation-catalog-font-line-height: var(--line-height);

    --sf-product-price-presentation-current-font-size: var(--font-size);
    --sf-product-price-presentation-current-font-line-height: var(--line-height);

    --sf-product-price-per-font-size-sm: var(--font-size-sm);
    --sf-product-price-per-font-line-height-sm: var(--font-size-sm);

    --sf-product-price-current-font-size-sm: var(--font-size-sm);
    --sf-product-price-current-font-weight-sm: var(--font-weight-regular);

    --sf-product-price-gap-row: 0.25rem;

  }

  .price {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &--row {
      flex-direction: row;
      justify-content: flex-end;
      text-align: right;
      gap: var(--sf-product-price-gap-row);
    }

    &--presentation {
      .price__catalog {
        font-size: var(--sf-product-price-presentation-catalog-font-size);
        line-height: var(--sf-product-price-presentation-catalog-font-line-height);
      }

      .price__current {
        font-size: var(--sf-product-price-presentation-current-font-size);
        line-height: var(--sf-product-price-presentation-current-font-line-height);
      }
    }

    &--small {
      .price__per {
        font-size: var(--sf-product-price-per-font-size-sm);
        line-height: var(--sf-product-price-per-font-line-height-sm);
      }

      .price__current {
        font-weight: var(--sf-product-price-current-font-weight-sm);
        font-size: var(--sf-product-price-current-font-size-sm);
      }
    }

    &__catalog {
      text-decoration: line-through;
      color: var(--sf-product-price-catalog-color);
      font-size: var(--sf-product-price-catalog-font-size);
      line-height: var(--sf-product-price-catalog-font-line-height);
      font-weight: var(--sf-product-price-catalog-font-weight);
    }

    &__current {
      color: var(--sf-product-price-current-color);
      font-size: var(--sf-product-price-current-font-size);
      line-height: var(--sf-product-price-current-font-line-height);
      font-weight: var(--sf-product-price-current-font-weight);
    }

    &__per {
      display: block;
      font-size: var(--sf-product-price-per-font-size);
      line-height: var(--sf-product-price-per-font-line-height);
      font-weight: var(--sf-product-price-per-font-weight);
      color: var(--sf-product-price-per-color);
    }
  }
</style>
