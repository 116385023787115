<script setup lang="ts">
  import { computed } from 'vue';
  import { UnitFormatter } from '$support';

  const props = defineProps({
    item: { type: Object, required: true },
  });

  const amount = computed(() => {
    return UnitFormatter.short(props.item.stock_unit, props.item.amount);
  });
</script>
<template>
  <SfListCard class="checkout-summary-item">
    <template #image>
      <MediaPicture :photo="item.photo" sizes="140x140 2x, 70x70" class="checkout-summary-item__image" />
    </template>
    <template #default>
      <header class="checkout-summary-item__header">
        <h3 class="checkout-summary-item__title">{{ item.variant_title }}</h3>
        <p v-if="item.variant_sku" class="checkout-summary-item__sku">SKU: {{ item.variant_sku }}</p>
        <SfProductCatalogPrice :item="item" class="checkout-summary-item__catalog" />
        <p class="checkout-summary-item__count" v-html="amount" />
      </header>
      <section class="checkout-summary-item__section">
        <SfProductOmnibus :price="item" class="checkout-summary-item__omnibus" />
      </section>
    </template>
    <template #actions>
      <section class="checkout-summary-item__price">
        <SfProductPrice :price="item" class="checkout-summary-item__price-value" />
      </section>
    </template>
  </SfListCard>
</template>
<style lang="scss">
  .checkout-summary-item {
    --sf-list-card-padding-x: 1rem;
    --sf-list-card-padding-y: 0.75rem;
    --sf-list-card-gap: 0.75rem;

    .list-card__image {
      flex: 0 0 70px;
    }

    &__header {
      font-size: 0.75rem;
      line-height: 1.5;
    }

    &__title {
      font-size: 0.875rem;
    }

    &__count {
      font-size: 0.75rem;
    }

    &__catalog {
      justify-content: flex-start !important;
      text-align: left !important;
    }
  }
</style>
